import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  v2ex,
  eleduck,
  apiUrl,
  contractTypes,
  hn,
  linkedin,
} from "../../common/Constant";
import JobDetail from "./JobDetail";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../iconfont/iconfont.js";
import ScrollPenetrate from "../../common/ScrollPenetrate.jsx";
import useRequest from "../../hooks/useRequest";

function JobList() {
  const [modalVisible, modalHide] = ScrollPenetrate(document.scrollingElement);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobCnt, setJobCnt] = useState(100);
  const [jobCategories, setJobCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [contractType, setContractType] = useState([]);

  useEffect(() => {
    getJobList(page, jobs, selectedCategory, contractType);
  }, []);

  useRequest(apiUrl.JOB_CATEGORY, ({ data }) => {
    const categoryArr = data.data.split("--").map((item) => {
      return { value: item, label: item };
    });
    setJobCategories(categoryArr);
  });

  const handleFilter = (category, conType) => {
    getJobList(1, [], category, conType);
  };

  const getJobList = async (
    pageIn,
    jobsIn,
    selectedCategoryParam,
    contractTypeParam
  ) => {
    const selectedCategoryStr = selectedCategoryParam.join("--");
    const contractTypeStr = contractTypeParam.join("--");
    const postsUrl = `${apiUrl.POST_LIST}page=${pageIn}&jobCategory=${selectedCategoryStr}&contractType=${contractTypeStr}`;
    try {
      if (loading) return;
      setLoading(true);
      const api = await fetch(postsUrl);
      const rs = await api.json();
      if (rs.success) {
        const newData = [...jobsIn, ...rs.data];
        setJobCnt(rs.totalRecordCnt);
        setJobs(newData);
        setPage(pageIn + 1);
        setLoading(false);
      } else {
        console.error("Fetch job data backend error: " + rs.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Fetch job data backend error: " + error);
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState({});
  const closeModal = () => {
    modalHide();
    setOpen(false);
  };

  return (
    <Wrapper>
      <FilterWrapper>
        <StyledSelect
          onChange={(e) => {
            setSelectedCategory(e.map((item) => item.value));
            handleFilter(
              e.map((item) => item.value),
              contractType
            );
          }}
          placeholder="请选择岗位类别"
          options={jobCategories}
          isMulti
        />
        <StyledSelect
          onChange={(e) => {
            setContractType(e.map((item) => item.value));
            handleFilter(
              selectedCategory,
              e.map((item) => item.value)
            );
          }}
          placeholder="请选择合同类型"
          options={contractTypes}
          isMulti
        />
      </FilterWrapper>
      {JobDetail(currentJob, open, closeModal)}
      <InfiniteScroll
        dataLength={jobs.length}
        next={() => {
          getJobList(page, jobs, selectedCategory, contractType);
        }}
        hasMore={jobs.length < jobCnt}
      >
        {processJobData(jobs).map((job) => {
          return (
            <CardWapper
              onClick={() => {
                setOpen((o) => !o);
                setCurrentJob(job);
                modalVisible();
              }}
            >
              <CardImageWapper>
                <CardImage src={job.imgUrl} alt={job.source} />
              </CardImageWapper>
              <CardTextWapper>
                <TextLeft>
                  <Title>{job.jobTitle}</Title>
                  {/* <Company>{job.companyTxt}</Company> */}
                  <Infos>
                    {!isEmpty(job.jobTypeTxt) && <Info>{job.jobTypeTxt}</Info>}
                    {!isEmpty(job.salaryTxt) && (
                      <Info showup="550">
                        <Icon aria-hidden="true">
                          <use xlinkHref="#icon-qiandai"></use>
                        </Icon>
                        {job.salaryTxt}
                      </Info>
                    )}
                    {!isEmpty(job.companyTxt) && (
                      <Info showup="550">
                        <Icon aria-hidden="true">
                          <use xlinkHref="#icon-gongsi"></use>
                        </Icon>
                        {job.companyTxt}
                      </Info>
                    )}
                  </Infos>
                </TextLeft>
                <UpdateTime>{job.published}</UpdateTime>
              </CardTextWapper>
            </CardWapper>
          );
        })}
      </InfiniteScroll>
    </Wrapper>
  );
}

function processJobData(jobs) {
  const rs = [];
  for (let i = 0; i < jobs.length; i++) {
    const newJob = {};
    const job = jobs[i];
    let imageUrl = "";
    switch (job.source) {
      case eleduck.SOURCE_NAME:
        imageUrl = eleduck.LOGO_URL;
        break;
      case v2ex.SOURCE_NAME:
        imageUrl = v2ex.LOGO_URL;
        break;
      case hn.SOURCE_NAME:
        imageUrl = hn.LOGO_URL;
        break;
      case linkedin.SOURCE_NAME:
        imageUrl = linkedin.LOGO_URL;
        break;
    }
    newJob.imgUrl = imageUrl;
    newJob.jobCode = job.jobCode;
    newJob.postTitle = job.postTitle;
    newJob.jobTitle = job.jobTitle;
    newJob.url = job.url;
    newJob.descContent = job.descContent;
    newJob.salary = job.salary;
    let salaryTxt = "";
    if (job.salary.includes("未知") || job.salary === "") {
      salaryTxt = "面议";
    } else {
      salaryTxt = job.salary;
    }
    newJob.salaryTxt = salaryTxt;
    newJob.company = job.company;
    const companyTxt = newJob.company.includes("未知") ? "" : newJob.company;
    newJob.companyTxt = companyTxt;
    newJob.companyIndustry = job.companyIndustry;
    newJob.jobType = job.jobType;
    let jobTypeTxt = "";
    if (job.jobType.includes("全职")) {
      jobTypeTxt = "远程全职";
    } else if (job.jobType.includes("兼职")) {
      jobTypeTxt = "远程兼职";
    }
    newJob.jobTypeTxt = jobTypeTxt;
    newJob.source = job.source;
    newJob.jobPublishTime = job.jobPublishTime;
    newJob.published = calcTimeDiff(job.jobPublishTime);
    let contactTxt = "";
    if (!job.contact.includes("未知")) {
      contactTxt = job.contact;
    }
    newJob.contactTxt = contactTxt;
    newJob.contact = job.contact;
    newJob.tags = job.tags;
    newJob.jobCategory = job.jobCategory;
    rs.push(newJob);
  }
  return rs;
}

function calcTimeDiff(dateStr) {
  const dt = Date.parse(dateStr);
  const current = Date.now();
  const hours = Math.ceil((current - dt) / (1000 * 60 * 60));
  if (hours < 24) {
    return hours + "小时前";
  } else if (hours >= 24 && hours < 24 * 30) {
    return Math.floor(hours / 24) + "天前";
  } else {
    return Math.floor(hours / (24 * 30)) + "月前";
  }
}

function isEmpty(str) {
  if (typeof str == "undefined" || str === null || str === "") {
    return true;
  } else {
    return false;
  }
}

export default JobList;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 70rem;
  box-sizing: border-box;
  @media only screen and (max-width: 750px) {
    width: 98%;
  }
`;

const CardWapper = styled.div`
  margin: 0.6rem 0rem; //top and bottom | left and right
  display: flex;
  height: 6rem;
  border-radius: 1rem;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

const CardImageWapper = styled.div`
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.2rem;
  height: 4.2rem;
  margin: 0.9rem;
  border-style: solid;
  border-radius: 50%;
  border-color: #c0c0c0;
  border-width: 2px;
`;

const CardImage = styled.img`
  border-radius: 50%;
  margin: 0rem 0rem;
  width: 80%;
  object-fit: contain;
`;

const CardTextWapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const TextLeft = styled.div`
  width: 83%;
  display: grid;
  grid-template-areas:
    "title"
    "company"
    "infos";
  grid-template-rows: 4fr 1fr 4fr;
  padding: 0.45rem 0rem;
`;

const Title = styled.div`
  padding-top: 0.6rem;
  line-height: 1.5rem;
  grid-area: title;
  margin: 0rem 1rem;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1rem;
  text-transform: uppercase;
`;

const Company = styled.div`
  grid-area: company;
  margin: 0rem 1rem;
  font-size: 0.9rem;
`;

const Infos = styled.div`
  margin: 0rem 1rem;
  grid-area: infos;
  display: flex;
`;

const Info = styled.div`
  height: 1.7rem;
  line-height: 1.7rem;
  display: flex;
  margin-right: 3rem;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  border-radius: 0.85rem;
  font-size: 0.8rem;
  padding: 0rem 0.8rem;

  ${(props) => {
    if (!("showup" in props)) return;
    return `
      @media only screen and (max-width: ${props.showup}px) {
        display: none;
      }
    `;
  }}
`;

const Icon = styled.svg`
  width: 1em;
  height: 2em;
  text-align: center;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  padding-right: 0.2rem;
`;

const UpdateTime = styled.div`
  width: 17%;
  height: 6rem;
  line-height: 6rem;
  // &::before {
  //   content: "收录于：";
  //   display: inline-block;
  //   font-size: 12px;
  //   color: var(--text-2);
  // }
`;

const FilterWrapper = styled.div`
  width: 100%;
  max-width: 70rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 20rem;
  height: auto;
  margin-right: 2rem;
  font-size: 0.85rem;
`;

const Filter = styled.button`
  margin-left: 7rem;
  width: 5rem;
  font-size: 1rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  border: 1px solid #c0c0c0;
  letter-spacing: 2px;
  &:hover {
    background-color: white;
    cursor: pointer;
  }
`;
